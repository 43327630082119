import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Cyber Psychogeography
Using Internet all day provides us with a strange strength. A new one, which we are get used to it as fast as our movement in cyberspace. The inter-connected
structure of web is skilled us to teleport ourselves distancelessly. Hyperlinks can be used to connect any two points on the web regardless of semantic relevance. These escape points also give us the security we want to flee at anytime from boredom and something more interesting. Instead of traveling far, Hyperlinks brings our requirement near to us and make them available. We pause and watch. Inspired by distancless nature of cyberspace, I decided to bring my home in Tehran into my arm reach. Close enough that I can drive to that in an hour like the time I drove home from school.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=WgnwVwVPxuc&feature=emb_logo"
      }}>{`Watch video`}</a></p>
    <p>{`This project is a psychogeographical journey through a combined map of Tehran and Sanfrancisco in which the starting point is my home in the latter and the
destination is my home in the former. For the purpose of this project I chose Mission Street as the foundation of my imaginary map. For me Mission Street in San Francisco always recalls the Jomhouri Street in Tehran. ‘Jomhouri” means “Republic” in Tehran. “Mission” and “Republic”, both goes back to a group of people. Both of these cities shape an identical perception in my mind: Abandoned theaters, street vendors and the chaotic movement of people. I overlaid these two streets on top of each other (which is to my surprise are similar in shape) and create a combined map of these two cities. I found my home in the waters adjacent Yerba Buena Island in the new map.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/sanfranblog1.jpg",
        "alt": "sf_1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/jomhuritoblog.jpg",
        "alt": "sf_jomhuri"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/MissionJomhooriblog.jpg",
        "alt": "mission_jomhouri"
      }}></img></p>
    <p>{`Finding home in Tehran in San Francisco Map`}</p>
    <p>{`PlayPrev|Next1 of 3
2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      